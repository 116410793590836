import React from "react"
import styled from "styled-components"

const ReplayButtonWrapper = styled.div`
  display: grid;
  width: "100%";
  justify-items: center;
`
const ButtonStyle = styled.button`
  position: relative;
  background: none;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border-top: 5px solid var(--button-color);
  border-right: 5px solid transparent;
  border-bottom: 1px solid var(--button-color);
  border-left: 5px solid var(--button-color);
  text-transform: uppercase;
  font-family: "Jost-Medium";
  font-size: 1rem;
  outline: none;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid var(--button-color);
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    transform: rotate(-45deg);
  }
  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
`

export default function ReplayButton(props) {
  return (
    <ReplayButtonWrapper>
      <ButtonStyle
        type="button"
        onClick={() => {
          props.setShowAnimation(true)
          props.setGiftOpen(false)
          props.setOpenGreeting(false)
          props.setShowGreeting(false)
        }}
      >
        Replay
      </ButtonStyle>
    </ReplayButtonWrapper>
  )
}

const ReplayExperienceBtn = styled.a`
  background: #2d2d2d;
  border: 2px solid #2d2d2d;
  border-radius: 10px;
  outline: none;
  padding: 0.75rem 2rem;
  margin: 5px;
  width: 165px;
  height: 63px;
  display: grid;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in;
  @media (max-width: 800px) {
    min-width: "300px";
  }
  @media (max-width: 500px) {
    min-width: "200px";
    margin: 5px 0;
  }
  font-size: var(--button-font-size);
  font-family: var(--bold-paragraph-font);
  text-transform: uppercase;
  color: white;
  &:hover {
    background: var(--button-color);
    border: 2px solid var(--button-color);
  }
`

export function ReplayButtonV2(props) {
  return (
    <ReplayButtonWrapper>
      <ReplayExperienceBtn
        onClick={() => {
          props.setGiftOpen(false)
          props.setOpenGreeting(false)
          props.setShowGreeting(false)
          props.setShowAnimation(false)
          setTimeout(() => {
            if (props.animation || props.greetingcard) {
              if (props.animation) {
                props.setShowAnimation(true)
              } else if (props.greetingcard) {
                props.setShowGreeting(true)
              }
            } else {
              props.setGiftOpen(true)
            }
          }, 500)
        }}
      >
        Replay
      </ReplayExperienceBtn>
    </ReplayButtonWrapper>
  )
}
